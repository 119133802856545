<template>
  <div>
    <!-- 公司资讯首图 -->
    <div class="NewsBanner">
      <img src="@/assets/img/news/banner-news.png" />
    </div>
    <!-- 切换 -->
    <div class="NewsSwitch">
      <div
        @click="handleSwitchChange(0)"
        :class="activeID == 0 ? 'NewsSwitch_itemActive' : 'NewsSwitch_item'"
      >
        公司资讯
      </div>
      <div
        @click="handleSwitchChange(1)"
        :class="activeID == 1 ? 'NewsSwitch_itemActive' : 'NewsSwitch_item'"
      >
        行业资讯
      </div>
    </div>
    <!-- 行业资讯 -->
    <div class="NewsContent">
      <el-carousel
        ref="carousel"
        :initial-index="0"
        :autoplay="false"
        indicator-position="none"
        arrow="never"
        :height="newList"
      >
        <el-carousel-item
          v-for="(item, index) in NewsIndustryTotal"
          :key="index"
        >
          <div class="NewsIndustry" ref="NewList">
            <div class="NewsIndustry_list">
              <div
                class="NewsIndustry_listItem"
                @click="goNewsDetails('/newsDetails', info)"
                v-for="(info, infoIndex) in item.NewsIndustryList"
                :key="infoIndex"
              >
                <div class="NewsIndustry_listItem_img">
                  <img :src="info.imgUrl" />
                </div>
                <div class="NewsIndustry_listItem_info">
                  <div class="NewsIndustry_listItem_infoTitle">
                    <div>{{ info.title }}</div>
                  </div>
                  <div class="NewsIndustry_listItem_infoContent">
                    {{ info.info }}
                  </div>
                  <div class="NewsIndustry_listItem_infoTime">
                    <div class="iconfont iconshijian"></div>
                    <div class="">{{ info.time }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="NewsIndustry_page">
              <el-pagination
                layout="prev, pager, next"
                :page-size="5"
                @current-change="(page) => handleCurrentChange(index, page)"
                :total="item.NewsIndustryLists.length"
              >
              </el-pagination>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { listThree, listTwo } from "@/api/list";
export default {
  data() {
    return {
      // 选中Tab
      activeID: 0,
      // 内容高度
      newList: 0,
      // 行业资讯数据列表-总
      NewsIndustryTotal: [
        {
          NewsIndustryList: [],
          NewsIndustryLists: listTwo,
        },
        {
          // 展示行业资讯列表
          NewsIndustryList: [],
          // 行业资讯数据列表
          NewsIndustryLists: listThree,
        },
      ],
    };
  },
  created() {
    this.NewsIndustryTotal.map((item) => {
      item.NewsIndustryList = item.NewsIndustryLists.slice(0, 5);
    });
  },
  mounted() {
    this.getContentH();
  },
  methods: {
    // 获取内容高度
    getContentH() {
      this.$nextTick(() => {
        let { clientHeight } = this.$refs.NewList[0];
        this.newList = `${clientHeight + 120}px`;
      });
    },
    // 跳转详情
    goNewsDetails(url, item) {
      this.$router.push({
        path: url,
        query: {
          title: item.title,
          time: item.time,
          content: item.content,
        },
      });
    },
    // 处理分页
    handleCurrentChange(index, page) {
      this.$nextTick(() => {
        this.NewsIndustryTotal[index].NewsIndustryList = [
          ...this.NewsIndustryTotal[index].NewsIndustryLists.slice(
            page * 5 - 5,
            page * 5
          ),
        ];
      });
    },
    // 切换
    handleSwitchChange(index) {
      this.activeID = index;
      this.$refs.carousel.setActiveItem(index);
    },
  },
};
</script>

<style scope>
/* 公司资讯首图 */
.NewsBanner img {
  display: flex;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.columnTitle_bg {
  position: relative;
  margin-top: 10px;
}
.columnTitle_bg > div:nth-of-type(1) {
  position: absolute;
  width: 60px;
  height: 5px;
  background-color: #000180;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.columnTitle_bg > div:nth-of-type(2) {
  width: 200px;
  height: 1px;
  background-color: #000;
}

/* 切换 */
.NewsSwitch {
  width: 1440px;
  margin: 120px auto 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.NewsSwitch_itemActive {
  cursor: pointer;
  transition: all 0.5s;
  box-sizing: border-box;
  width: 83%;
  font-size: 30px;
  color: #002780;
  padding: 8px 20px;
  border-bottom: 1px solid #002780;
}
.NewsSwitch_item {
  cursor: pointer;
  transition: all 0.5s;
  box-sizing: border-box;
  width: 13%;
  font-size: 30px;
  color: #cccccc;
  padding: 8px 20px;
  border-bottom: 1px solid #cccccc;
}

/* 行业资讯 */
.NewsContent {
  background-color: #f5f5f5;
  width: 100%;
}
.NewsContent > div:nth-child(2n + 1) {
  background-color: #fff;
}
.NewsIndustry {
  width: 1440px;
  margin: 0 auto;
}
.NewsIndustry_title {
  position: relative;
}
.NewsIndustry_title > div:nth-of-type(1) {
  font-size: 40px;
  color: #e5e5e5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NewsIndustry_title > div:nth-of-type(2) {
  font-size: 30px;
  color: #002780;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.columnTitle_bg {
  position: relative;
  margin-top: 10px;
}
.columnTitle_bg > div:nth-of-type(1) {
  position: absolute;
  width: 60px;
  height: 5px;
  background-color: #000180;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.columnTitle_bg > div:nth-of-type(2) {
  width: 200px;
  height: 1px;
  background-color: #000;
}
/* 行业资讯-内容 */
.NewsIndustry_content {
  display: flex;
  justify-content: space-between;
  margin: 100px 0;
  height: 400px;
  box-sizing: border-box;
}
.NewsIndustry_content > div {
  width: 46%;
}
.NewsIndustry_contentInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.NewsIndustry_contentInfo_subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #323232;
  line-height: 48px;
}
.NewsIndustry_contentInfo_time {
  font-size: 18px;
  font-weight: 400;
  color: #cccccc;
  line-height: 48px;
}
.NewsIndustry_contentInfo_content {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.NewsIndustry_contentInfo_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.NewsIndustry_contentInfo_btn > div {
  width: 160px;
  height: 40px;
  border: 1px solid #000080;
  font-size: 16px;
  font-weight: 400;
  color: #000080;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NewsIndustry_contentInfo_btn > div:hover {
  background-color: #000080;
  color: #fff;
}
.NewsIndustry_contentImg {
  width: 560px;
}
.NewsIndustry_contentImg img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
  border-radius: 10px;
}
/* 公司资讯-列表 */
.NewsIndustry_listItem {
  display: flex;
  margin-bottom: 60px;
  justify-content: flex-start;
}
.NewsIndustry_listItem:hover .NewsIndustry_listItem_infoContent {
  color: #636399;
}
.NewsIndustry_listItem:hover
  .NewsIndustry_listItem_infoTitle
  > div:nth-of-type(1) {
  color: #000080;
}
.NewsIndustry_listItem:hover
  .NewsIndustry_listItem_infoTitle
  > div:nth-of-type(2) {
  color: #636399;
}
.NewsIndustry_listItem > div:nth-of-type(2) {
  margin-left: 40px;
  width: 100%;
}
.NewsIndustry_listItem_infoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 14px;
  box-sizing: border-box;
  font-size: 24px;
}
.NewsIndustry_listItem_infoTitle > div:nth-of-type(1) {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.NewsIndustry_listItem_infoTime {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}
.NewsIndustry_listItem_infoTime > div:nth-of-type(1) {
  font-size: 20px;
  color: #999999;
  margin-right: 12px;
}
.NewsIndustry_listItem_infoTime > div:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.NewsIndustry_listItem_infoContent {
  margin-top: 40px;
  font-size: 16px;
  color: #999999;
  line-height: 30px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-indent: 2em;
}
.NewsIndustry_listItem_img img {
  width: 360px;
  height: 240px;
  display: flex;
  object-fit: cover;
  border-radius: 12px;
}
/* 公司资讯-分页 */
.NewsIndustry_page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px auto 120px;
}
.el-pagination button:disabled {
  background-color: #f5f5f5;
}
.el-dialog,
.el-pager li {
  background-color: #f5f5f5;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background-color: #f5f5f5;
}
</style>